@font-face {
  font-family: 'Fira Sans';
  src: url('./FiraSans-Light.ttf');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('./FiraSans-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('./FiraSans-Medium.ttf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('./FiraSans-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('./FiraSans-SemiBold.ttf');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('./FiraSans-Bold.ttf');
  font-style: normal;
  font-display: swap;
  font-weight: bold;
}
