.slick-list {
  height: 100%;
}

$clock-height: 40px;
$font-size: 24px;
$clock-width: 200px;

.group-message-container {
  position: fixed;
  bottom: 3rem;
  width: 100%;
  z-index: 999;

  display: flex;
  justify-content: space-between;

  .clock-container {
    width: $clock-width;
    height: $clock-height;
    font-size: $font-size;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > div {
      width: calc(#{$clock-width} - 100px);
      height: 100%;
      background-color: #000000b0;
      text-align: right;
      color: #fff;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }

  .marquee-text-container {
    width: calc(100% - #{$clock-width});
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    position: relative;
    z-index: 999;
    .overlay {
      height: auto;
    }

    .marquee-text {
      font-size: $font-size;
      height: $clock-height;
      color: #000;
      font-weight: 500;
    }
  }
}
