#sidebar_container {
  width: 100vw;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: transform 500ms ease-in-out;

  .bg-sidebar {
    z-index: 1;
    position: absolute;
  }
  .sidebar {
    position: absolute;
    width: 50vw;
    height: 100vh;
    background-color: rgba(10, 12, 12, 0.7);
    @media (max-width: 1300px) {
      width: 640px;
    }
    z-index: 2;
  }

  .Sidebar_parent_container {
    .Sidebar_parent_container-item {
      cursor: pointer;
      width: 560px;
      height: 740px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        font-size: 80px;
      }
      .border_item_sidebar--active {
        border-radius: 80px;
        border: 8px solid #f80000;
      }
      .border_item_sidebar {
        border-radius: 80px;
        border: 8px solid #ffffff;
      }
      @media (max-width: 3000px) {
        width: 140px;
        height: 185px;
        p {
          font-size: 20px;
        }
        .border_item_sidebar--active {
          border-radius: 20px !important;
          border: 2px solid #f80000;
        }
        .border_item_sidebar {
          border-radius: 20px !important;
          border: 2px solid #ffffff !important;
        }
      }
      @media (min-width: 3001px) and (max-width: 5000px) {
        width: 280px;
        height: 370px;
        p {
          font-size: 40px;
        }
        .border_item_sidebar--active {
          border-radius: 40px !important;
          border: 4px solid #f80000;
        }
        .border_item_sidebar {
          border-radius: 40px !important;
          border: 4px solid #ffffff !important;
        }
      }

      .container_icon {
        width: 320px;
        height: 320px;
        padding: 32px;
        @media (max-width: 3000px) {
          width: 72px;
          height: 72px;
          padding: 8px;
        }
        @media (min-width: 3001px) and (max-width: 5000px) {
          width: 160px;
          height: 160px;
          padding: 16px;
        }
        img {
          width: 100%;
          height: 100&;
        }
      }
    }
    .bg_container_sidebar {
      background-color: rgba(112, 112, 112, 0.3);
    }
  }

  .Sidebar_children_container {
    flex: 1;
    .Sidebar_children_container--header {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 2.34vw 2.6vw;
      img {
        width: 2vw;
        height: 2vw;
        cursor: pointer;
      }
    }
    .Sidebar_children-device-id {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .sidebar_item_lang {
      padding: 1.875vw;
      height: 6.25vw;
      cursor: pointer;
      img {
        width: 3.9vw;
        margin: 0px 1.5625vw 0px 3vw;
      }
      .item_dot {
        width: 1vw;
        height: 1vw;
        border: 1px solid rgb(139, 139, 139);
        border-radius: 50%;
        margin: 0px 0.5vw;
      }
    }
    .bg-children_item {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.btn_reload_data--focused {
  transform: scale(1.1);
}

.show_sidebar {
  transform: translateX(0);
}
.hide_sidebar {
  transform: translateX(-100%);
}
