.primary-button {
  background-color: #f80000;
  border-color: #f80000;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  border-radius: 40px;
  &:hover {
    background-color: #f80000;
  }
  @media (max-width: 3839px) {
    border-radius: 10px;
  }
  @media (max-width: 7639px) and (min-width: 3840px) {
    border-radius: 20px;
  }
}

.home_page {
  overflow: hidden;
  .playlist_container {
    .title_frame {
      position: absolute !important;
      bottom: 20px;
      font-size: 30px;
    }

    .playlist_split_content_vertical {
      .frame_split {
        width: 100%;
        height: 50%;
      }
    }
    .playlist_split_content_horizontal {
      display: flex;
      .frame_split {
        width: 50%;
        height: 100%;
      }
    }
  }

  .slick-slider {
    width: 100%;
    height: 100%;
    .slick-track {
      height: 100%;
    }
    .slick-slide {
      > div {
        height: 100%;
      }
    }
  }
  .slick-initialized .slick-active {
    z-index: 3;
  }

  @keyframes textAni {
    0% {
      left: 0;
    }
    50% {
      left: calc(100% - 60px);
    }
    100% {
      left: 0;
    }
  }

  .check_auth {
    margin: 0px auto;
    padding-top: 50px;
    flex-wrap: wrap;
    align-items: center;
    color: #fff;
  }

  .empty_data {
    .title {
      width: max-content;
      font-size: 35px;
    }
    .btn_change_device {
      width: fit-content;
      height: fit-content;
      padding: 8px 18px;
      font-size: 25px;
    }

    .btn_refresh_data {
      width: 100%;
      height: fit-content;
      padding: 8px 18px;
      font-size: 25px;
    }

    .btn_change_device--active {
      background-color: #f15202;
    }
    .btn_refresh_data--active {
      background-color: #f15202;
      color: white;
    }
  }
}

.video-js {
  width: 100%;
  height: 100%;
}

.no_connect {
  background: url('../../../assets/images/bg-1.webp');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .wrapper-vertical {
    flex-direction: column;
    align-content: space-between;
    @media (min-height: 1280px) and (max-height: 1919px) {
      height: 1000px;
    }
    @media (min-height: 1920px) and (max-height: 2559px) {
      height: 1570px;
    }
    @media (min-height: 2560px) and (max-height: 3839px) {
      height: 2270px;
    }
    @media (min-height: 3840px) {
      height: 3370px;
    }
  }

  .wrapper-horizontal {
    @media (min-width: 1280px) and (max-width: 1919px) {
      width: 1000px;
    }
    @media (min-width: 1920px) and (max-width: 2559px) {
      width: 1570px;
    }
    @media (min-width: 2560px) and (max-width: 3839px) {
      width: 2100px;
    }
    @media (min-width: 3840px) {
      width: 3370px;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    .logo {
      width: 220px;
      &.logo-horizontal {
        @media (min-width: 1280px) and (max-width: 1919px) {
          width: 260px;
        }
        @media (min-width: 1920px) and (max-width: 2559px) {
          width: 300px;
        }
        @media (min-width: 2560px) and (max-width: 3839px) {
          width: 500px;
        }
        @media (min-width: 3840px) {
          width: 800px;
        }
      }
      &.logo-vertical {
        @media (min-height: 1280px) and (max-height: 1919px) {
          width: 260px;
        }
        @media (min-height: 1920px) and (max-height: 2559px) {
          width: 300px;
        }
        @media (min-height: 2560px) and (max-height: 3839px) {
          width: 500px;
        }
        @media (min-height: 3840px) {
          width: 800px;
        }
      }
    }
    .container_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      backdrop-filter: blur(24px);
      -webkit-backdrop-filter: blur(24px);
      border-radius: 30px;
      opacity: 1;

      &.container_content-horizontal {
        width: calc(100% - 260px - 3rem);
        min-height: 500px;
        padding: 20px 30px;
        @media (min-width: 1280px) and (max-width: 1919px) {
          width: 500px;
          height: 550px;
          padding: 20px 30px;

          border-radius: 20px;
        }
        @media (min-width: 1920px) and (max-width: 2559px) {
          width: 660px;
          height: 780px;
          padding: 30px 50px;
          border-radius: 30px;
        }
        @media (min-width: 2560px) and (max-width: 3839px) {
          width: 900px;
          height: 1100px;
          padding: 40px 60px;
          border-radius: 40px;
        }
        @media (min-width: 3840px) {
          width: 1500px;
          height: 1600px;
          padding: 40px 60px;
          border-radius: 50px;
        }
      }
      &.container_content-vertical {
        width: 600px;
        height: calc(100% - 220px - 3rem);
        @media (min-height: 1280px) and (max-height: 1919px) {
          width: 500px;
          height: 550px;
          padding: 20px 30px;
          border-radius: 20px;
        }
        @media (min-height: 1920px) and (max-height: 2559px) {
          width: 660px;
          height: 780px;
          padding: 30px 50px;
          border-radius: 30px;
        }
        @media (min-height: 2560px) and (max-height: 3839px) {
          width: 1100px;
          height: 1180px;
          padding: 40px 60px;
          border-radius: 40px;
        }
        @media (min-height: 3840px) {
          width: 1500px;
          height: 1680px;
          padding: 70px 90px;
          border-radius: 50px;
        }
      }
    }
  }

  .img_not_playlist {
    &.img_not_playlist-horizontal {
      @media (min-width: 1280px) and (max-width: 1919px) {
        width: 220px;
      }
      @media (min-width: 1920px) and (max-width: 2559px) {
        width: 320px;
      }
      @media (min-width: 2560px) and (max-width: 3839px) {
        width: 520px;
      }
      @media (min-width: 3840px) {
        width: 820px;
      }
    }
    &.img_not_playlist-vertical {
      @media (min-height: 1280px) and (max-height: 1919px) {
        width: 220px;
      }
      @media (min-height: 1920px) and (max-height: 2559px) {
        width: 320px;
      }
      @media (min-height: 2560px) and (max-height: 3839px) {
        width: 520px;
      }
      @media (min-height: 3840px) {
        width: 820px;
      }
    }
  }

  .title {
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
    &.title-horizontal {
      @media (min-width: 1280px) and (max-width: 1919px) {
        font-size: 24px;
      }
      @media (min-width: 1920px) and (max-width: 2559px) {
        font-size: 34px;
      }
      @media (min-width: 2560px) and (max-width: 3839px) {
        font-size: 54px;
      }
      @media (min-width: 3840px) {
        font-size: 75px;
      }
    }
    &.title-vertical {
      @media (min-height: 1280px) and (max-height: 1919px) {
        font-size: 24px;
      }
      @media (min-height: 1920px) and (max-height: 2559px) {
        font-size: 34px;
      }
      @media (min-height: 2560px) and (max-height: 3839px) {
        font-size: 54px;
      }
      @media (min-height: 3840px) {
        font-size: 75px;
      }
    }
  }

  .description_not_playlist {
    font-size: 24px;
    text-align: center;
    color: #ffffff;
    &.description_not_playlist-horizontal {
      @media (min-width: 1280px) and (max-width: 1919px) {
        font-size: 18px;
      }
      @media (min-width: 1920px) and (max-width: 2559px) {
        font-size: 24px;
      }
      @media (min-width: 2560px) and (max-width: 3839px) {
        font-size: 35px;
      }
      @media (min-width: 3840px) {
        font-size: 55px;
      }
    }
    &.description_not_playlist-vertical {
      @media (min-height: 1280px) and (max-height: 1919px) {
        font-size: 18px;
      }
      @media (min-height: 1920px) and (max-height: 2559px) {
        font-size: 24px;
      }
      @media (min-height: 2560px) and (max-height: 3839px) {
        font-size: 35px;
      }
      @media (min-height: 3840px) {
        font-size: 55px;
      }
    }
  }

  .fs_24-horizontal {
    @media (min-width: 1280px) and (max-width: 1919px) {
      font-size: 18px;
    }
    @media (min-width: 1920px) and (max-width: 2559px) {
      font-size: 24px;
    }
    @media (min-width: 2560px) and (max-width: 3839px) {
      font-size: 35px;
    }
    @media (min-width: 3840px) {
      font-size: 55px;
    }
  }
  .fs_24-vertical {
    @media (min-height: 1280px) and (max-height: 1919px) {
      font-size: 18px;
    }
    @media (min-height: 1920px) and (max-height: 2559px) {
      font-size: 24px;
    }
    @media (min-height: 2560px) and (max-height: 3839px) {
      font-size: 35px;
    }
    @media (min-height: 3840px) {
      font-size: 55px;
    }
  }

  .fs_54-horizontal {
    font-size: 32px;
    @media (min-width: 1280px) and (max-width: 1919px) {
      font-size: 45px;
    }
    @media (min-width: 1920px) and (max-width: 2559px) {
      font-size: 54px;
    }
    @media (min-width: 2560px) and (max-width: 3839px) {
      font-size: 60px;
    }
    @media (min-width: 3840px) {
      font-size: 90px;
    }
  }
  .fs_54-vertical {
    font-size: 30px;
    @media (min-height: 1280px) and (max-height: 1919px) {
      font-size: 45px;
    }
    @media (min-height: 1920px) and (max-height: 2559px) {
      font-size: 54px;
    }
    @media (min-height: 2560px) and (max-height: 3839px) {
      font-size: 60px;
    }
    @media (min-height: 3840px) {
      font-size: 90px;
    }
  }

  .fs_30-horizontal {
    @media (min-width: 1280px) and (max-width: 1919px) {
      font-size: 25px;
    }
    @media (min-width: 1920px) and (max-width: 2559px) {
      font-size: 30px;
    }
    @media (min-width: 2560px) and (max-width: 3839px) {
      font-size: 45px;
    }
    @media (min-width: 3840px) {
      font-size: 60px;
    }
  }
  .fs_30-vertical {
    @media (min-height: 1280px) and (max-height: 1919px) {
      font-size: 25px;
    }
    @media (min-height: 1920px) and (max-height: 2559px) {
      font-size: 30px;
    }
    @media (min-height: 2560px) and (max-height: 3839px) {
      font-size: 45px;
    }
    @media (min-height: 3840px) {
      font-size: 65px;
    }
  }
  .loading_cpn {
    &.loading_cpn-horizontal {
      @media (min-width: 1280px) and (max-width: 1919px) {
        transform: scale(1.5);
      }
      @media (min-width: 1920px) and (max-width: 2559px) {
        transform: scale(3);
      }
      @media (min-width: 2560px) and (max-width: 3839px) {
        transform: scale(5);
      }
      @media (min-width: 3840px) {
        transform: scale(7);
      }
    }
    &.loading_cpn-vertical {
      @media (min-height: 1280px) and (max-height: 1919px) {
        transform: scale(1.5);
      }
      @media (min-height: 1920px) and (max-height: 2559px) {
        transform: scale(3);
      }
      @media (min-height: 2560px) and (max-height: 3839px) {
        transform: scale(5);
      }
      @media (min-height: 3840px) {
        transform: scale(7);
      }
    }
  }
}
