// @import '~antd/dist/antd.css';

@import './mixin.scss';
@import '../font/font.scss';
@import '~bootstrap/scss/bootstrap';

body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

#root {
  background-color: white;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

$input-font-size: 30px;

.primary-btn {
  background-color: #f26922;
  border-color: #f26922;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $input-font-size;
  height: auto;
  width: 100%;
  &:hover,
  &:active {
    border-color: #f15202;
    color: #fff;
    background-color: #f15202;
  }
  &:focus {
    border-color: #69618a;
    color: #fff;
    box-shadow: 0 0 0 4px #ffffff;
    background-color: #34323d;
  }
}

.input {
  font-size: $input-font-size;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 20px;
}

.text-50 {
  @media (max-width: 1550px) {
    font-size: 33px;
  }
  @media (max-width: 3839px) and (min-width: 1551px) {
    font-size: 50px;
  }

  @media (max-width: 7639px) and (min-width: 3840px) {
    font-size: 100px;
  }

  font-size: 200px;
}

.text-40 {
  font-size: 160px;
  @media (max-width: 1550px) {
    font-size: 27px;
  }
  @media (max-width: 3839px) and (min-width: 1551px) {
    font-size: 40px;
  }
  @media (max-width: 7639px) and (min-width: 3840px) {
    font-size: 80px;
  }
}

.text-34 {
  font-size: 128px;
  @media (max-width: 1550px) {
    font-size: 23px;
  }
  @media (max-width: 3839px) and (min-width: 1551px) {
    font-size: 34px;
  }
  @media (max-width: 7639px) and (min-width: 3840px) {
    font-size: 64px;
  }
}

.text-54 {
  font-size: 216px;
  @media (max-width: 1550px) {
    font-size: 36px;
  }
  @media (max-width: 3839px) and (min-width: 1551px) {
    font-size: 54px;
  }

  @media (max-width: 7639px) and (min-width: 3840px) {
    font-size: 108px;
  }
}

.text-24 {
  font-size: 96px;
  @media (max-width: 1550px) {
    font-size: 16px;
  }
  @media (max-width: 3839px) and (min-width: 1551px) {
    font-size: 24px;
  }

  @media (max-width: 7639px) and (min-width: 3840px) {
    font-size: 48px;
  }
}

.text-20 {
  font-size: 80px;
  @media (max-width: 1550px) {
    font-size: 15px;
  }
  @media (max-width: 3839px) and (min-width: 1551px) {
    font-size: 20px;
  }
  @media (max-width: 7639px) and (min-width: 3840px) {
    font-size: 40px;
  }
}

.text-30 {
  font-size: 120px;
  @media (max-width: 1550px) {
    font-size: 20px;
  }
  @media (max-width: 3839px) and (min-width: 1551px) {
    font-size: 30px;
  }
  @media (max-width: 7639px) and (min-width: 3840px) {
    font-size: 60px;
  }
}

.text-50-ver {
  @media (max-height: 1550px) {
    font-size: 33px;
  }
  @media (max-height: 3839px) and (min-height: 1551px) {
    font-size: 50px;
  }

  @media (max-height: 7639px) and (min-height: 3840px) {
    font-size: 100px;
  }

  font-size: 200px;
}

.text-40-ver {
  font-size: 160px;
  @media (max-height: 1550px) {
    font-size: 27px;
  }
  @media (max-height: 3839px) and (min-height: 1551px) {
    font-size: 40px;
  }
  @media (max-height: 7639px) and (min-height: 3840px) {
    font-size: 80px;
  }
}

.text-34-ver {
  font-size: 128px;
  @media (max-height: 1550px) {
    font-size: 23px;
  }
  @media (max-height: 3839px) and (min-height: 1551px) {
    font-size: 34px;
  }
  @media (max-height: 7639px) and (min-height: 3840px) {
    font-size: 64px;
  }
}

.text-54-ver {
  font-size: 216px;
  @media (max-height: 1550px) {
    font-size: 36px;
  }
  @media (max-height: 3839px) and (min-height: 1551px) {
    font-size: 54px;
  }

  @media (max-height: 7639px) and (min-height: 3840px) {
    font-size: 108px;
  }
}

.text-24-ver {
  font-size: 96px;
  @media (max-height: 1550px) {
    font-size: 16px;
  }
  @media (max-height: 3839px) and (min-height: 1551px) {
    font-size: 24px;
  }

  @media (max-height: 7639px) and (min-height: 3840px) {
    font-size: 48px;
  }
}

.text-20-ver {
  font-size: 80px;
  @media (max-height: 1550px) {
    font-size: 15px;
  }
  @media (max-height: 3839px) and (min-height: 1551px) {
    font-size: 20px;
  }
  @media (max-height: 7639px) and (min-height: 3840px) {
    font-size: 40px;
  }
}

.text-30-ver {
  font-size: 120px;
  @media (max-height: 1550px) {
    font-size: 20px;
  }
  @media (max-height: 3839px) and (min-height: 1551px) {
    font-size: 30px;
  }
  @media (max-height: 7639px) and (min-height: 3840px) {
    font-size: 60px;
  }
}

.text-70 {
  font-size: 70px;
}

.letter-spacing-9 {
  letter-spacing: 9.72px;
}

.bg-opacity-3 {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-opacity-5 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-opacity-7 {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-white-opacity-3 {
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-opacity-5 {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-opacity-7 {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-opacity-9 {
  background-color: rgba(255, 255, 255, 0.9);
}

.text-bold {
  font-weight: bold;
}

.text-red {
  color: #f80000;
}

.logo-rs {
  width: 20vw;
}
.img-rs {
  width: 15vw;
}

.logo-rs-ver {
  width: 30vw;
}
.img-rs-ver {
  width: 30vw;
}

.ant-notification-notice-message {
  letter-spacing: 0px;
  color: #222b45;
  text-transform: uppercase;
  font-size: 64px;
  font-weight: bold;
  @media (max-height: 1550px) {
    font-size: 16px !important;
  }
  @media (max-height: 3839px) and (min-height: 1551px) {
    font-size: 16px !important;
  }
  @media (max-height: 7639px) and (min-height: 3840px) {
    font-size: 32px !important;
  }
}

.ant-notification-notice-description {
  letter-spacing: 0px;
  color: #8f9bb3;
  font-size: 64px;
  @media (max-height: 1550px) {
    font-size: 16px !important;
  }
  @media (max-height: 3839px) and (min-height: 1551px) {
    font-size: 16px !important;
  }
  @media (max-height: 7639px) and (min-height: 3840px) {
    font-size: 32px !important;
  }
}

.text-loading {
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  &:before {
    content: attr(data-text);
    position: absolute;
    bottom: 0;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    color: #fff;
    animation: loading 1.5s linear;
    animation-iteration-count: infinite;
  }
  &.without-loading {
    color: #fff;
    &:before {
      animation: unset;
    }
  }
}

.text-error {
  position: relative;
  color: #f80000;
  font-weight: bold;
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}
